import React from 'react';
import styled from 'styled-components';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const PaginationList = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px;
  justify-content: center;

  & li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  & li.active button {
    color: ${({ theme }) => theme.colors.black} !important;
    background-color: ${({ theme }) => theme.colors.offWhite} !important;
    border-color: ${({ theme }) => theme.colors.black} !important;
  }

  & li:first-child button {
    margin-left: 0;
    border-radius: 50%;
  }

  & li:last-child button {
    border-radius: 50%;
    margin-right: 0;
  }

  & button {
    text-decoration: none;
    min-width: 50px;
    text-align: center;
    box-shadow: none !important;
    border-color: ${({ theme }) => theme.colors.black} !important;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
    font-size: 100%;

    margin: 0px 10px;
    border-radius: 50%;
    position: relative;
    display: block;
    margin-left: -1px;
    line-height: 48px;
    height: 50px;
    max-height: 50px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.black};
  }

  & button:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  & button:hover {
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.white};
  }
`;

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from: number, to: number, step = 1) => {
  let i = from;
  const rangeArray = [];

  while (i <= to) {
    rangeArray.push(i);
    i += step;
  }

  return rangeArray;
};

const Pagination = ({
  onPageChanged,
  totalRecords = null,
  pageLimit = 30,
  pageNeighbours = 0,
  currentPage = 1,
}: {
  onPageChanged: (properties: {
    currentPage: number;
    totalPages: number;
    pageLimit: number;
  }) => void;
  totalRecords: number;
  pageLimit: number;
  pageNeighbours: number;
  currentPage: number;
}): React.ReactElement => {
  const totalPages = Math.ceil(totalRecords / pageLimit);

  const gotoPage = (page: number) => {
    const calculatedCurrentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage: calculatedCurrentPage,
      totalPages,
      pageLimit,
      totalRecords,
    };

    onPageChanged(paginationData);
  };

  const handleClick = (page) => {
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage + 1);
  };

  /**
   * 
   * This current version is a simple version of pagination 
   * that mimic's how the public websites pagination works
   * 
   * It will look like in most cases
   * 
   * < {1} [2] {3} >
   * 
   * On edge cases (first and last page) it should look like 
   * 
   * < [1] {2} >
   * 
   * and
   * 
   * < {9} [10] >
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(1, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      return [LEFT_PAGE, ...pages, RIGHT_PAGE]
    }

    return range(1, totalPages);
  };

  if (!totalRecords || totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <>
      <nav aria-label="Pagination">
        <PaginationList>
          {pages.map((page) => {
            if (page === LEFT_PAGE)
              return (
                <li key={page}>
                  <button
                    type="button"
                    aria-label="Previous"
                    disabled={currentPage <= 1}
                    onClick={handleMoveLeft}
                  >
                    <span aria-hidden="true">&lsaquo;</span>
                  </button>
                </li>
              );
            if (page === RIGHT_PAGE)
              return (
                <li key={page}>
                  <button
                    type="button"
                    aria-label="Next"
                    disabled={currentPage >= totalPages}
                    onClick={handleMoveRight}
                  >
                    <span aria-hidden="true">&rsaquo;</span>
                  </button>
                </li>
              );

            return (
              <li
                key={page}
                className={`${currentPage === page ? ' active' : ''}`}
              >
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick(page);
                  }}
                >
                  {page}
                </button>
              </li>
            );
          })}
        </PaginationList>
      </nav>
    </>
  );
};

export default Pagination;
