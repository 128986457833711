import React from 'react';
import { Box } from '@deckee/deck-hand';
import styled from 'styled-components';
import Protected from './protected';
import { ErrorBoundary } from 'react-error-boundary';

interface LayoutBodyProps {
  children: React.ReactNode;
  protect?: boolean;
  className?: string;
}

// Standard layout body
export const LayoutBody = ({
  className,
  children,
  protect,
}: LayoutBodyProps) => {
  return (
    <ErrorBoundary fallbackRender={() => <></>} onError={console.warn}>
      <Box className={className} m="2">
        {protect ? <Protected>{children}</Protected> : children}
      </Box>
    </ErrorBoundary>
  );
};

export const TWO_COLUMNS_MIN_WIDTH = '750px';
export const FIRST_COLUMN_WIDTH = '320px';

export const TwoColumnLayout = styled(LayoutBody)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${TWO_COLUMNS_MIN_WIDTH}) {
    flex-direction: row;
  }
`;

const FirstColumnBox = styled(Box)`
  padding-bottom: ${({ theme }) => theme.space[3]}px;
  @media (min-width: ${TWO_COLUMNS_MIN_WIDTH}) {
    min-width: ${FIRST_COLUMN_WIDTH};
    max-width: ${FIRST_COLUMN_WIDTH};
    margin-right: ${({ theme }) => theme.space[3]}px;
  }
`;

const SecondColumnBox = styled(Box)`
  flex: 1 1 0px;
  min-width: 0;
`;

const StickyBox = styled(Box)`
  @media (min-width: ${TWO_COLUMNS_MIN_WIDTH}) {
    position: sticky;
    top: 80px;
  }
`;

interface ColumnProps {
  sticky?: boolean;
  children: React.ReactNode;
}
export const FirstColumn = ({ sticky, children }: ColumnProps) => {
  const Wrapper = sticky ? StickyBox : React.Fragment;
  return (
    <FirstColumnBox>
      <Wrapper>{children}</Wrapper>
    </FirstColumnBox>
  );
};

export const SecondColumn = ({ sticky, children }: ColumnProps) => {
  const Wrapper = sticky ? StickyBox : React.Fragment;
  return (
    <SecondColumnBox>
      <Wrapper>{children}</Wrapper>
    </SecondColumnBox>
  );
};
